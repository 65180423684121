import { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaucetHealthResponse } from "../libraries/FaucetRequestTypes";
import AlchemyLogoComponent from "./AlchemyLogoComponent";
import AuthCheckComponent, { AuthCheckProps } from "./AuthCheckComponent";
import FaucetHealthComponent from "./FaucetHealthComponent";
import "../../assets/css/ClassicApp.css";
import useServerData from "../hooks/useServerData";
import { useNavigate } from "react-router-dom";
import {
  ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK,
  FaucetChainNetworkKey,
} from "../../shared/models/types";
import { NetworkSelect } from "./NetworkSelect";

export const ClassicSingleFaucetNavigationBar = memo(
  function ClassicNavigationBar({
    affiliateReferral,
    healthData,
    authData,
  }: {
    affiliateReferral: string;
    healthData?: FaucetHealthResponse;
    authData: AuthCheckProps;
  }) {
    const { APP_NETWORK_INFO, SUPPORTED_NETWORK_CONFIGS } = useServerData();

    const [selectedNetworkId, setSelectedNetworkId] =
      useState<FaucetChainNetworkKey>(APP_NETWORK_INFO.id);

    const navigate = useNavigate();
    useEffect(() => {
      // This would be less error-prone and a better UX to use client-side routing via React Router
      // instead of relying on server-side routing via window.location.pathname.
      // Unfortunately this will require a larger refactor of than we have time for. See:
      // https://github.com/OMGWINNING/alchemy-faucet/blob/master/src/app/FaucetApp.tsx#L55
      const target = `/faucets/${ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK[selectedNetworkId]}`;
      if (window.location.pathname !== target) {
        window.location.pathname = target;
      }
    }, [selectedNetworkId, navigate]);

    return (
      <Container
        className="alchemy-faucet-nav"
        style={{ position: "relative" }}
      >
        <Row className="alchemy-faucet-beta">
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: 10,
              alignItems: "center",
            }}
            className="alchemy-faucet-health-nav-col"
          >
            Powered by&nbsp;
            <span style={{ marginTop: 1, marginRight: 10 }}>
              <AlchemyLogoComponent affiliateReferral={affiliateReferral} />
            </span>
            {healthData && (
              <FaucetHealthComponent key="1" faucetHealth={healthData} />
            )}
          </Col>

          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className="alchemy-faucet-login-nav-col"
          >
            <div style={{ marginRight: 10 }}>
              <NetworkSelect
                networkConfig={SUPPORTED_NETWORK_CONFIGS}
                selectedNetworkId={selectedNetworkId}
                setSelectedNetworkId={setSelectedNetworkId}
              />
            </div>
            {APP_NETWORK_INFO.requiresAuthentication && <AuthCheckComponent {...authData} />}
          </Col>
        </Row>
      </Container>
    );
  },
);
