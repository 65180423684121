import "../assets/css/ClassicApp.css";
import { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import FaucetRequestComponent, {
  FaucetRequestAuthenticationState,
} from "./components/FaucetRequestComponent";
import FaucetRequestTable from "./components/FaucetRequestTable";
import {
  FaucetRequestItem,
  FaucetRequestStatus,
} from "./libraries/FaucetRequestTypes";
import { BaseBlockChain, FaucetClientServerData } from "../shared/models/types";
import FaucetBanner from "./components/FaucetBanner";
import FaucetLinkComponent from "./components/FaucetLinkComponent";
import useServerData from "./hooks/useServerData";

interface FaucetUIActions {
  setAddress: (address: string) => void;
  didRequest: (reCAPTCHAValue: string) => void;
  bannerClose: () => void;
}

interface FaucetUIRenderData {
  serverData: FaucetClientServerData;
  title: string;
  subtitle: string;
  toAddress: string;
  requestItems: FaucetRequestItem[];
  requestStatus: FaucetRequestStatus;
  faq: JSX.Element;
  affiliateReferral: string;
  tokenName: string;
  chain: BaseBlockChain;
  requestUpsell: JSX.Element;
  requestAuthState: FaucetRequestAuthenticationState;
  bannerInnerHtml?: string;
  stage?: string;
}

export default function FaucetAppContent({
  renderData,
  actions,
}: {
  renderData: FaucetUIRenderData;
  actions: FaucetUIActions;
}) {
  const [selectedNetwork, setSelectedNetwork] = useState(true);
  const flipSelectedNetwork = useCallback(
    () => setSelectedNetwork((value) => !value),
    [setSelectedNetwork],
  );

  const { SUPPORTED_NETWORK_CONFIGS, APP_NETWORK_INFO } = useServerData();

  return (
    <>
      <div className="alchemy-app-content-container">
        <Container
          className="alchemy-faucet-title-component"
          style={{ position: "relative", paddingLeft: 0, paddingRight: 0 }}
        >
          <Row>
            <Col>
              <h1 className="alchemy-faucet-title">
                {renderData.title}
                {renderData.stage && (
                  <>
                    {" "}
                    <span
                      style={{
                        border: "3px solid lightgray",
                        padding: "0px 5px",
                        color: "lightgray",
                        borderRadius: 5,
                      }}
                    >
                      {renderData.stage}
                    </span>
                  </>
                )}
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs={12} md={12} lg={8}>
              <p
                className="alchemy-faucet-subtitle"
                style={{ fontSize: 18, maxWidth: 801, margin: "auto" }}
              >
                {renderData.subtitle}
              </p>
            </Col>
          </Row>
          <FaucetBanner
            show={renderData.bannerInnerHtml !== undefined}
            innerHtml={renderData.bannerInnerHtml}
            onClose={actions.bannerClose}
          />
        </Container>
        <Container
          className="alchemy-faucet-request-component"
          style={{
            paddingTop: 36,
            paddingBottom: 16,
            borderRadius: "24px 24px 0px 0px",
            position: "relative",
          }}
        >
          <div
            className="alchemy-faucet-request-component-shadow"
            style={{ position: "absolute", top: 0, left: 0 }}
          ></div>
          <Row>
            <Col className="alchemy-faucet-request-component-embeded">
              <FaucetRequestComponent
                address={renderData.toAddress}
                addressChanged={actions.setAddress}
                addRequest={actions.didRequest}
                status={renderData.requestStatus}
                tokenName={renderData.tokenName}
                chain={renderData.chain}
                requestUpsell={renderData.requestUpsell}
                authenticationState={renderData.requestAuthState}
                recaptchaClientKey={renderData.serverData.RECAPTCHA_CLIENT_KEY}
                recaptchaClientType={
                  renderData.serverData.RECAPTCHA_CLIENT_TYPE
                }
              />
            </Col>
          </Row>
        </Container>
        <Container
          className="alchemy-faucet-request-component"
          style={{
            marginBottom: 30,
            borderRadius: "0px 0px 24px 24px",
            borderTop: "1px solid #D9D9D9",
            position: "relative",
          }}
        >
          <div
            className="alchemy-faucet-request-component-shadow"
            style={{ position: "absolute", top: 0, left: 0 }}
          ></div>
          <div
            className="alchemy-faucet-request-component-embeded"
            style={{ marginTop: 32, marginBottom: 36 }}
          >
            {APP_NETWORK_INFO.requiresAuthentication && (
              <FaucetRequestTable
                items={renderData.requestItems}
                chain={renderData.chain}
              />
            )}
          </div>
        </Container>
        <Container
          style={{
            marginBottom: 30,
            borderRadius: "0px 0px 24px 24px",
            position: "relative",
          }}
        >
          <Row>
            <Col style={{ margin: 0, padding: 0 }}>
              <div className="alchemy-dialog" style={{ borderRadius: 24 }}>
                <h2 className="alchemy-dialog-title">FAQs</h2>
                {renderData.faq}
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          className="alchemy-faucet-link-footer-container"
          style={{
            borderRadius: "0px 0px 24px 24px",
            position: "relative",
          }}
        >
          <Row>
            <Col style={{ margin: 0, padding: 0 }}>
              <div className="alchemy-dialog" style={{ borderRadius: 24 }}>
                <h2 className="alchemy-dialog-title">Try Our Other Faucets!</h2>
                <div className="alchemy-faucet-link-footer-group" role="group">
                  {SUPPORTED_NETWORK_CONFIGS.map((appConfig) => {
                    return (
                      <FaucetLinkComponent
                        key={appConfig.id}
                        network={appConfig}
                        currentNetwork={APP_NETWORK_INFO}
                        className={classNames("alchemy-faucet-link-footer", {
                          "alchemy-faucet-link-footer-current-network":
                            APP_NETWORK_INFO.id === appConfig.id &&
                            selectedNetwork,
                        })}
                        flipSelectedNetwork={flipSelectedNetwork}
                      />
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid style={{ paddingTop: 15 }}>
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <p
                className="alchemy-brand-tagline"
                style={{ margin: "0px 10px" }}
              >
                © Alchemy Insights Inc. 2022 -- Version 1.2.0
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
