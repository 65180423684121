import { memo } from "react";
import { HelperStrings } from "../../shared/constants";
import { FaucetAppNetworkInfo } from "../../shared/models/types";
import FaucetTwitterComponent from "../components/FaucetTwitterComponent";
import {
  evmAddressFAQ,
  missingTokensFAQ,
  captchaFailFAQ,
  failedAbuseCheckFAQ,
  cookiesEnabledFAQ,
  minimumBalanceFAQ,
  needAdditionalOptimism,
} from "./FAQConstants";
import Utils from "../../shared/frontendUtils";

interface FAQContentProps {
  nonAuthDrip: string;
  authDrip: string;
  referral: string;
  loginRedirect: () => void;
  networkInfo: FaucetAppNetworkInfo;
  reserveWalletAddress: string;
}

export const ClassicMumbaiFAQContent = memo(function MumbaiFAQContent({
  nonAuthDrip,
  authDrip,
  referral,
  networkInfo,
  reserveWalletAddress,
}: FAQContentProps) {
  const authSpecificContent = networkInfo.requiresAuthentication ? (
    <>
      <b>How do I use this?</b>
      <p>
        To request funds, simply sign into with Alchemy, enter your wallet
        address, and hit “Send Me MATIC”. We support wallets as received
        addresses but not smart contracts.
      </p>
      <b>How does it work?</b>
      <p>
        You can request {authDrip} every 24h without any authentication. Then
        create a{" "}
        <a
          href={`https://alchemy.com/?a=${referral}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          free Alchemy account
        </a>
        !
      </p>
    </>
  ) : (
    <>
      <b>How do I use this?</b>
      <p>
        To request funds, simply enter your wallet address and hit “Send Me{" "}
        MATIC”. We support wallets as received addresses but not smart
        contracts.
      </p>
      <b>How does it work?</b>
      <p>
        You can request {nonAuthDrip} every 24h without any authentication. Then
        create a{" "}
        <a
          href={`https://alchemy.com/?a=${referral}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          free Alchemy account
        </a>{" "}
        to start building!
      </p>
    </>
  );
  return (
    <>
      {authSpecificContent}
      <b>What is a testnet Polygon faucet?</b>
      <p>
        A Polygon faucet is a developer tool to get testnet MATIC in order to
        test and troubleshoot your decentralized application or protocol before
        going live on Polygon mainnet, where one must use real MATIC. Most
        faucets require social authentication (e.g. Twitter post or login
        confirming you are a real human) or place you in a queue to wait for a
        testnet token through the faucet. The Alchemy Mumbai faucet is free,
        fast, and does not require authentication, though you can optionally
        login to Alchemy to get an increased drip.
      </p>
      <b>What is a testnet token?</b>
      <p>
        Testnet tokens are a test currency that allows you to test your Polygon
        application before going live on mainnet. Testnet tokens can be used in
        place of mainnet MATIC tokens on testnets like Mumbai. You can read more{" "}
        <a
          href={HelperStrings.TESTNET_DOC_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </p>
      {networkInfo.minMainnetBalanceRequired && minimumBalanceFAQ(networkInfo)}
      {evmAddressFAQ}
      {missingTokensFAQ}
      {captchaFailFAQ}
      {failedAbuseCheckFAQ()}
      {cookiesEnabledFAQ}
      <b>It worked! How can I say thank you?</b>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <p style={{ marginBottom: "0px" }}>
          Help other developers by sharing your experience with a{" "}
        </p>
        <div style={{ marginLeft: 5, height: 20 }}>
          <FaucetTwitterComponent size={"small"} appNetworkInfo={networkInfo} />
        </div>
      </div>
      <b>
        What if I run into any other issues, have questions, or want more Mumbai{" "}
        Matic?
      </b>
      <p style={{ marginBottom: 0, paddingBottom: 0 }}>
        Join our{" "}
        <a
          href={HelperStrings.DISCORD_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Discord
        </a>{" "}
        or fill out this{" "}
        <span
          dangerouslySetInnerHTML={{ __html: HelperStrings.EMBEDDED_TYPEFORM }}
        />
        .<br />
        If you have extra Mumbai Matic to donate to help other developers, we
        accept donations at{" "}
        <span className="alchemy-faucet-code-text">{reserveWalletAddress}</span>
        .
      </p>
    </>
  );
});

interface EthContentExtension {
  testnet: string;
}

export const ClassicEthFAQContent = memo(function EthFAQContent({
  testnet,
  nonAuthDrip,
  authDrip,
  referral,
  networkInfo,
  reserveWalletAddress,
}: FAQContentProps & EthContentExtension) {
  const authSpecificContent = networkInfo.requiresAuthentication ? (
    <>
      <b>How do I use this?</b>
      <p>
        To request funds, simply sign into with Alchemy, enter your wallet
        address, and hit “Send Me ETH”. We support wallets as received addresses
        but not smart contracts.
      </p>
      <b>How does it work?</b>
      <p>
        You can request {authDrip} every{" "}
        {Utils.secondsToHours(networkInfo.rateLimitDurationSeconds)} hours with
        a{" "}
        <a
          href={`https://alchemy.com/?a=${referral}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          free Alchemy account
        </a>
        !
      </p>
    </>
  ) : (
    <>
      <b>How do I use this?</b>
      <p>
        To request funds, simply enter your wallet address and hit “Send Me{" "}
        ETH”. We support wallets as received addresses but not smart contracts.
      </p>
      <b>How does it work?</b>
      <p>
        You can request {nonAuthDrip} every 24h without any authentication. Then
        create a{" "}
        <a
          href={`https://alchemy.com/?a=${referral}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          free Alchemy account
        </a>{" "}
        to start building!
      </p>
    </>
  );
  return (
    <>
      {authSpecificContent}
      {needAdditionalOptimism(networkInfo)}
      <b>What is a testnet Ethereum faucet?</b>
      <p>
        An Ethereum faucet is a developer tool to get testnet Ether (ETH) in
        order to test and troubleshoot your decentralized application or
        protocol before going live on Ethereum mainnet, where one must use real
        Ether. Most faucets require social authentication (e.g. Twitter post or
        login confirming you are a real human) or place you in a queue to wait
        for a testnet token through the faucet. The Alchemy {testnet} faucet is
        free, fast, and does not require authentication, though you can
        optionally login to Alchemy to get an increased drip.
      </p>
      <b>What is a testnet token?</b>
      <p>
        Testnet tokens are a test currency that allows you to test your Ethereum
        application before going live on mainnet. Testnet tokens can be used in
        place of mainnet Ether tokens on testnets like {testnet}. You can read
        more{" "}
        <a
          href={HelperStrings.TESTNET_DOC_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
      {networkInfo.minMainnetBalanceRequired && minimumBalanceFAQ(networkInfo)}
      {evmAddressFAQ}
      {missingTokensFAQ}
      {captchaFailFAQ}
      {failedAbuseCheckFAQ()}
      {cookiesEnabledFAQ}
      <b>It worked! How can I say thank you?</b>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <p style={{ marginBottom: "0px" }}>
          Help other developers by sharing your experience with a{" "}
        </p>
        <div style={{ marginLeft: 5, height: 20 }}>
          <FaucetTwitterComponent size={"small"} appNetworkInfo={networkInfo} />
        </div>
      </div>
      <b>What if I run into any other issues, or have questions?</b>
      <p style={{ marginBottom: 0, paddingBottom: 0 }}>
        Join our{" "}
        <a
          href={HelperStrings.DISCORD_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Discord
        </a>{" "}
        or fill out this{" "}
        <span
          dangerouslySetInnerHTML={{ __html: HelperStrings.EMBEDDED_TYPEFORM }}
        />
        .<br />
        If you have extra {testnet} ETH to donate to help other developers, we
        accept donations at{" "}
        <span className="alchemy-faucet-code-text">{reserveWalletAddress}</span>
        .
      </p>
    </>
  );
});
